import React, { useState } from 'react'

import * as ReactBootStrap  from 'react-bootstrap';
export default function AlertDanger(props) {
    var errors = props.errors;
    
    
    return (
        <div>
            {props.alertBox && props.alertBox=='success' ?
                <ReactBootStrap.Alert variant="success" >
                    <p>
                    {props.message ? props.message : null}
                    </p>
                    {props.errors ? Object.keys(errors).map((errorItemKey, i) => (
                    <p key={i} >{errors[errorItemKey][0]}</p>
                    )) : null}

                </ReactBootStrap.Alert>
   
            :
                <ReactBootStrap.Alert variant="danger" >
                    <p>
                    {props.message ? props.message : null}
                    </p>
                    {props.errors ? Object.keys(errors).map((errorItemKey, i) => (
                    <p key={i} >{errors[errorItemKey][0]}</p>
                    )) : null}

                </ReactBootStrap.Alert>
            }
        </div>
            
    )
}
