import React, { Component } from 'react'
import Title from '../components/Title'

export default class Team extends Component {

    
    constructor(props) {
        super(props);
      }


    componentDidMount() {
        window.scrollTo(0, 0)
    }




      
    //this method for render cashouts
    renderTeamMembers(){
        const teams = this.props.teams;
        return(
            <React.Fragment>
                <div className="row">
                
                    {teams.map((team, index) => {
                     return(
                        <div className='col-sm-3'>
                            <div className='single_team mt-4'>
                                <img className='img-fluid' src={team.image}></img>
                                <div className='single_team_footer'>
                                    <h1>{team.name}</h1>
                                    <h4>{team.designation}</h4>
                                </div>
                            </div>
                        </div>
                     )
                    })}
                </div>
                
            </React.Fragment>
        );
    }

    render() {
        return (
            <>
            
      <Title title="Our Team" />
                <section className="team_section text-center py-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <h1 className="service_section_title">Our Team</h1>
                            </div>
                        </div>
                        <div className='row'>
                            {this.renderTeamMembers()}
                        </div>

                    </div>
                </section>

            </>
        )
    }
}
