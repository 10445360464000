import React, { Component } from 'react'
import { render } from 'react-dom';
import Gallery from 'react-grid-gallery';
import Title from '../components/Title';
export default class GalleryPage extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const IMAGES = this.props.gallerys;

        return (
            <>

                <Title title="Gallery" />
                <section className=" text-center py-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <h1 className="service_section_title">Photo Gallery</h1>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-sm-12 mt-3'>
                                <Gallery images={IMAGES} />
                            </div>
                        </div>
                    </div>
                </section>

            </>
        )
    }
}
