import React, { Component } from 'react'

export default class Title extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        const defaultTitle = 'Village IT Center';
        document.title = defaultTitle + ' | '+this.props.title;
    }
  render() {
    return (
      <></>
    )
  }
}
