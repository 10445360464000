import React, { Component } from 'react'
import OwlCarousel from 'react-owl-carousel2';
import '../../lib/owl.carousel.css';
import '../../lib/owl.theme.default.css';
export default class Carosel extends Component {

    constructor(props) {
        super(props);
    }


    //this method for render cashouts
    renderSliders() {
        const sliders = this.props.sliders;
        return (
            <React.Fragment>
                {sliders.map((slider, index) => {
                    return (
                        <div className='item' key={index}>
                            <img src={slider.image} alt={slider.title} />
                            <div className="cover">
                                <div className="container">
                                    <div className="header-content">
                                        <div className="line"></div>
                                        <h2>{slider.sub_title} </h2>
                                        <h1>{slider.title} </h1>
                                        <h4>{slider.description} </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}

            </React.Fragment>
        );
    }



    render() {
        const options = {
            items: 1,
            nav: true,
            rewind: true,
            autoplay: true,
            margin: 10,
            autoplay: true,
            dots: false
        };

        const events = {
            onDragged: function (event) { },
            onChanged: function (event) { }
        };

        return (
            <>
                <section className='carosel_section'>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-sm-12 no-padding-col'>
                                <div className='owlTemplate'>
                                    <OwlCarousel options={options} events={events} >
                                        {this.renderSliders()}
                                    </OwlCarousel>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}
