import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope,  faMapLocation, faPhone} from '@fortawesome/free-solid-svg-icons';
import {NavLink} from "react-router-dom";
export default class Footer extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const settings = this.props.settings;
        return (
            <>
                <section className='footer_section'>
                    <div className='container py-5'>
                        <div className='row'>
                            <div className='col-sm-4 mt-3'>

                            <NavLink className='footer_logo ' to="/">
                                    <img src={this.props.settings.footer_logo} alt="" className="img-fluid mb-3" />
                                </NavLink>


                                <p className='footer_contact'> <span><FontAwesomeIcon icon={faMapLocation} /></span> {this.props.settings.footer_address} </p>
                                <p className='footer_contact'> <span><FontAwesomeIcon icon={faPhone} /></span> {this.props.settings.footer_phone}</p>
                                <p className='footer_contact'> <span><FontAwesomeIcon icon={faEnvelope} /></span> {this.props.settings.footer_email}</p>

                               
                               <div className='facebookpage'>
                                    <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FVillage-It-Center-110896411712409%2F&tabs=timeline&width=340&height=200&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=1126405457750417"></iframe>

                               </div>

                            </div>
                            <div className='col-sm-4 mt-3'>
                                <h1 className='footer_widget_title'>Useful Links</h1>
                                <ul className='footer_menu'>
                                    <li><NavLink className={'nav-link'} to="/">Home</NavLink></li>
                                    <li><NavLink className={'nav-link'}  to="/courses">Courses</NavLink></li>
                                    <li><NavLink className={'nav-link'}  to="/team">Team</NavLink></li>
                                    <li><NavLink className={'nav-link'}  to="/gallery">Gallery</NavLink></li>
                                    <li><NavLink className={'nav-link'}  to="/feedback">Feedback</NavLink></li>
                                    <li><NavLink className={'nav-link'}  to="/contact">Contact</NavLink></li>
                                </ul>
                            </div>
                            <div className='col-sm-4 mt-3'>
                                <h1 className='footer_widget_title'>Opening Hours</h1>
                                <div className="opening-hours">
                                    <ul className="list-border">
                                        <li className="clearfix"> <span className='text-start'> Saturday:  </span>
                                            <div className="value text-end"> {settings.saturday_open} </div>
                                        </li>
                                        <li className="clearfix"> <span className='text-start'> Sunday:  </span>
                                            <div className="value float-right"> {settings.sunday_open} </div>
                                        </li>
                                        <li className="clearfix"> <span className='text-start'> Monday:  </span>
                                            <div className="value float-right">  {settings.monday_open} </div>
                                        </li>
                                        <li className="clearfix"> <span className='text-start'> Tuesday:  </span>
                                            <div className="value float-right"> {settings.tudesday_open} </div>
                                        </li>
                                        <li className="clearfix"> <span className='text-start'> Wednesday:  </span>
                                            <div className="value float-right"> {settings.wednesday_open} </div>
                                        </li>
                                        <li className="clearfix"> <span className='text-start'> Thursday:  </span>
                                            <div className="value float-right"> {settings.thursday_open} </div>
                                        </li>
                                        <li className="clearfix "> <span className='text-start'> Friday :</span>
                                            <div className="value float-right"> {settings.friday_open} </div>
                                        </li>


                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className='footer_bottom_section'>

                    <div className='container'>
                        <div className='row'>

                            <div className='col-sm-6 text-center text-sm-start'>
                                <p>
                                    { settings.footer_copyright }
                                </p>
                            </div>
                            <div className='col-sm-6 text-center text-sm-end'>
                                <p>
                                    Developed by <a target="_blank" href='https://webnestbd.com/'>Web Nest IT</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
              
            </>
        )
    }
}
