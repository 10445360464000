import React, { Component } from 'react'
import Loader from '../Images/loader.gif';
export default class PageLoader extends Component {
    render() {
        return (
            <>
                <div className="pageLoadingWrapper text-center w-100 h-100 py-5" >
                    <div className="PageLoadingBox  d-flex align-items-center justify-content-center h-100">
                        
                        <img src={Loader} />
                       
                    </div>
                </div>
            </>
        )
    }
}
