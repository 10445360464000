import React, { Component } from 'react'
import * as ReactBootStrap  from 'react-bootstrap';
export default class LodingBox extends Component {
    render() {
        return (
            <>
                <div className="loadingWrapper text-center w-100" >
                    <div className="loadingBox  d-flex align-items-center justify-content-center">
                        <ReactBootStrap.Spinner animation="border" className=""  variant="success" />
                       
                    </div>
                </div>
            </>
        )
    }
}
