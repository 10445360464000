import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faStarHalf } from '@fortawesome/free-solid-svg-icons';
import CourseImage1 from '../../Images/Course/data-web.png';
import CourseImage2 from '../../Images/Course/Digital-marketing-web.png';
import CourseImage3 from '../../Images/Course/Graphic-design-web.png';
import CourseImage4 from '../../Images/Course/office-Application.png';
import CourseImage5 from '../../Images/Course/web-design-web.png';
import CourseImage6 from '../../Images/Course/web-development.png';

export default class Course extends Component {

    constructor(props) {
        super(props);
    }




    //this method for render cashouts
    renderCourses() {
        const courses = this.props.courses;
        return (
            <React.Fragment>
                <div className="row">

                    {courses.map((course, index) => {
                        return (
                            <div className='col-sm-4' key={index}>
                                <div className='single_course mt-5 text-sm-start'>
                                    <div className='course_image'>
                                        <img className='img-fluid' src={course.image} />
                                    </div>
                                    <div className='course_footer'>
                                        <h3> {course.sub_title} </h3>
                                        <h1>{course.title}</h1>

                                        <div className="course_description"  dangerouslySetInnerHTML={{ __html: course.description }}></div>


                                    </div>

                                    <div className='row pb-3'>
                                        <div className='col-6'>
                                            <ul className='ratting'>
                                                <li><FontAwesomeIcon icon={faStar} /></li>
                                                <li><FontAwesomeIcon icon={faStar} /></li>
                                                <li><FontAwesomeIcon icon={faStar} /></li>
                                                <li><FontAwesomeIcon icon={faStar} /></li>
                                                <li><FontAwesomeIcon icon={faStar} /></li>
                                                <li><FontAwesomeIcon icon={faStarHalf} /></li>
                                            </ul>
                                        </div>
                                        <div className='col-6'> <p className='course_fee'>Course Fee: <span>{course.course_fee}TK</span></p> </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>

            </React.Fragment>
        );
    }

    render() {
        return (
            <>


                <section className="course_section text-center py-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <h1 className="service_section_title">Our Course</h1>
                            </div>
                        </div>

                        <div className="row">
                            {this.renderCourses()}

                        </div>

                    </div>
                </section>

            </>
        )
    }
}
