import React, { Component } from 'react'
import Hero from '../components/Home/Hero';
import Title from '../components/Title';
import LodingBox from '../components/LoadingBox';
import AlertDanger from '../components/AlertDanger';
import '../components/Globals';
import Axios from 'axios';

export default class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Auth: false,
            loading: false,
            errorMessage: false,
            SuccessMessage: false,
            message: '',
            alertBox: 'danger',
            submitData: {
                full_name: '',
                email: '',
                phone: '',
                message: ''
            },
            errors: {},
        };
    }

    submitProcess = async () => {

        this.setState({ loading: true });

        await Axios.post(global.apiUrl + '/contact', this.state.submitData)
            .then(response => {
                this.setState({ loginDetails: response.data });
                //if not logged in
                if (response.data.status == 'ERROR') {
                    this.setState({ errorMessage: true });
                    this.setState({ errors: response.data.errors });
                    this.setState({ message: response.data.message });
                    this.setState({ loading: false });
                } else {

                    this.setState({ errorMessage: true });
                    this.setState({ message: response.data.message });
                    this.setState({ alertBox: 'success' });
                 
                    this.setState({ loading: false });
                    document.getElementById("submit_form").reset();

                }
                this.setState({ loading: false });

            })
            .catch(function (error) {

            });
    }

    handleSubmit = async (event) => {
        this.setState({ loading: true });
        event.preventDefault();
        const answer = window.confirm("Are you sure ?");
        if (answer) {
            await this.submitProcess();
        } else {
            this.setState({loading:false});
        }

    }

    dataHandler = (e) => {
        const newData = this.state.submitData;
        newData[e.target.name] = e.target.value;
        this.setState({ submitData: newData });

    }

    render() {
        return (
            <>
                <Title title="Contact Us" />

                <section className=" py-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 text-center">
                                <h1 className="service_section_title">Have Some Questions ?</h1>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-sm-6 mt-5 hasLoading'>

                                {this.state.errorMessage ? <AlertDanger message={this.state.message} errors={this.state.errors} alertBox={this.state.alertBox} ></AlertDanger> : ''}
                                {this.state.loading ? <LodingBox></LodingBox> : ''}

                                <form id="submit_form" onSubmit={this.handleSubmit} >
                                    <div className='row'>
                                        <div className='col-sm-12'>
                                            <div className="mb-3">
                                                <label className="form-label">Full Name</label>
                                                <input name='full_name' onChange={(e) => this.dataHandler(e)} type="text" className="form-control" placeholder='Full  Name' required />
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">Email</label>
                                                <input name='email' onChange={(e) => this.dataHandler(e)} type="email" className="form-control" placeholder='Email' required />
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">Phone</label>
                                                <input name='phone' onChange={(e) => this.dataHandler(e)} type="text" className="form-control" placeholder='Phone' required />
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label">Message</label>
                                                <textarea name='message' onChange={(e) => this.dataHandler(e)} className="form-control" placeholder='Message' required></textarea>
                                            </div>

                                            <button type="submit" className="btn btn-primary red_button">Submit</button>
                                        </div>
                                    </div>
                                </form>
                            </div>

                            <div className='col-sm-6 mt-5'>
                                <div id="map-container-google-1" className="z-depth-1-half map-container" dangerouslySetInnerHTML={{ __html: this.props.settings.contact_map }}>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Hero settings={this.props.settings} />
            </>
        )
    }
}
