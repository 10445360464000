import React, { Component } from 'react'
import Service from '../components/Home/Service';
import Title from '../components/Title';

export default class ServicesPage extends Component {
    
  constructor(props) {
    super(props);
}

  render() {
    return (
        <>
        <Title title="Our Services" />
          <Service services={this.props.services} />
        </>
    )
  }
}
