import React, { Component } from 'react'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Notfound from './Notfound';
import Home from './Home';
import Header from '../components/Header';
import Footer from '../components/Footer';
import CoursesPage from './CoursesPage';
import Team from './Team';
import GalleryPage from './GalleryPage';
import Contact from './Contact';
import Apply from './Apply';
import Axios from 'axios';
import '../components/Globals';
import PageLoader from '../components/PageLoader';
import Feedback from './Feedback';
import ServicesPage from './ServicesPage';

export default class Parent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            loaded: false,
            store: {}
        };
    }

    async componentDidMount() {
        await this.getStore();
    }


    //this method for generation update 
    async getStore() {
        this.setState({ loading: true });
        const url = global.apiUrl + '/store';
        const response = await Axios.get(url);
        this.setState({ store: response.data });
        this.setState({ loading: false });
        this.setState({ loaded: true });
    }


    render() {
        return (
            <div className='parent_div pageLoader'>
                {this.state.loading ? <PageLoader></PageLoader> :null}
                {this.state.loaded ?
                    <Router>

                        <Header settings={this.state.store.settings} />
                        <Switch>
                            <Route exact path="/">
                                <Home settings={this.state.store.settings} services={this.state.store.services}  sliders={this.state.store.sliders}   courses={this.state.store.courses}  />
                            </Route>
                            <Route exact path="/courses">
                                <CoursesPage   courses={this.state.store.courses} />
                            </Route>
                            <Route exact path="/services">
                                <ServicesPage   services={this.state.store.services} />
                            </Route>
                            <Route exact path="/team">
                                <Team  teams={this.state.store.teams} />
                            </Route>
                            <Route exact path="/gallery">
                                <GalleryPage   gallerys={this.state.store.gallerys} />
                            </Route>
                            <Route exact path="/feedback">
                                <Feedback   feedbacks={this.state.store.feedbacks}  />
                            </Route>
                            <Route exact path="/contact">
                                <Contact settings={this.state.store.settings}  />
                            </Route>
                            <Route exact path="/admission">
                                <Apply settings={this.state.store.settings}   courses={this.state.store.courses}  />
                            </Route>
                            <Route>
                                <Notfound />
                            </Route>

                        </Switch>
                        <Footer settings={this.state.store.settings} />
                    </Router>
                    : null}
            </div>
        )
    }
}
