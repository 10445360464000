import React, { Component } from 'react'
import { NavLink } from "react-router-dom";
import Title from '../components/Title';
import Image404 from '../Images/404-image.jpg';

export default class Notfound extends Component {
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  render() {
    return (
      <>
      
      <Title title="404 Not Found" />
      <section className='error_404_section py-5'>
        <div className='container'>
          <div className='row'>
            <div className='col-sm-6'>
              <h1 className='notfound_title'>Page <br /> Not Found</h1>
              <p className='py-3'>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Saepe doloribus eveniet sit fugit officia eos vero voluptates nemo laboriosam laudantium nisi nulla corporis necessitatibus, accusantium quia recusandae ullam, ipsum dignissimos.</p>
              <NavLink to="/" className='btn red_button btn-danger'>Go Back</NavLink>
            </div>
            <div className='col-sm-6 d-none d-sm-block '>
              <img className='img-fluid ' src={Image404} />
            </div>
          </div>
        </div>
      </section>
      </>
    )
  }
}
